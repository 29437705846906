<template>
  <CriaTabSelPluri />
</template>

<script>
import CriaTabSelPluri from "@/components/tabSel/criacaoTSPluri/CriaTabSelPluri.vue";

export default {
  components: {
    CriaTabSelPluri,
  },
};
</script>
